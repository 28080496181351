.mySwiper-home {
    height: 333px !important;
}

.wins {
    /* height: auto !important; */
    background-color: #152140 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-image: linear-gradient(#0f1b38, #1e2b4c) !important;

    /* background-image: linear-gradient(180deg, #0f1b38, #1e2b4c 99%);
    -webkit-box-shadow: 0 0 20px 0 rgba(39, 39, 31, .5); */
    box-shadow: 0 0 20px 0 rgba(39, 39, 31, .5);
    /* borde; */
}


.img-winner {
    width: 100px;
    height: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: inline-block;
    padding-bottom: 60%;
    vertical-align: middle;
    background: #727272;
    border-radius: 5px;
}

.img-winner .inner-img {
    width: auto;
    height: 100%;
    position: absolute;
    left: -100%;
    right: -100%;
    margin: auto;
    max-width: none;
    border: solid 2px #727272;

}

.winner-right {
    height: 70% !important;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

/* text */
.winner-right span {
    font-size: 14px;
}


/* text end */