.other-links-left {
    position: fixed;
    top: 270px;
    left: 0;
    z-index: 15;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 10px 20px;
    width: auto;
    height: 250px;
    background-image: radial-gradient(circle at 50%, at 50%, #704dee 0, #2a0c97 95%);
    background-image: radial-gradient(circle at 50% 50%, #704dee 1%, #2a0c97 95%);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}

.other-links-left .fixed-right-img {
    width: 45px;
    height: 45px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
}

.other-links-left,
.other-links-left .fixed-right-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.other-links-left span {
    padding-left: 5px;
    width: 10px;
    white-space: nowrap;
    color: #fff;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

/* ----------- */
.other-links-right .links-content-block:hover {
    height: 100%;
}

.other-links-right .links-content-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px 10px 20px;
    width: auto;
    height: 210px;
    background-image: radial-gradient(circle at 50%, at 50%, #e29f1d, #764e02 89%);
    background-image: radial-gradient(circle at 50% 50%, #e29f1d, #764e02 89%);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}


.other-links-right {
    position: fixed;
    top: 270px;
    right: 0;
    z-index: 15;
}

.other-links-right .box,
.other-links-right .box .imgs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.other-links-right .box .imgs img {
    width: 35px;
    height: 35px;
    -webkit-filter: brightness(800%) grayscale(0);
    filter: brightness(800%) grayscale(0);
}

.other-links-right .box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.other-links-right .box span {
    padding-left: 5px;
    width: 10px;
    white-space: nowrap;
    color: #fff;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

/* links info */
.other-links-right .links-content-block:hover .links-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.other-links-right .links-info {
    display: none;
    margin-right: 10px;
}



.other-links-right .links-content-block:hover {
    height: 100%;
}

.other-links-right .links-content-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px 10px 20px;
    width: auto;
    height: 210px;
    background-image: radial-gradient(circle at 50%, at 50%, #e29f1d, #764e02 89%);
    background-image: radial-gradient(circle at 50% 50%, #e29f1d, #764e02 89%);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}

.links-content-block:hover .links-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.other-links-right .links-info .fixed-friendlink-nav {
    width: 45px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 5px;
    -webkit-box-shadow: 1px 1px 2px 0 rgba(85, 85, 85, .5);
    box-shadow: 1px 1px 2px 0 rgba(85, 85, 85, .5);
}

.other-links-right .links-info .fixed-friendlink-nav .links-icon {
    width: 45px;
    height: 45px;
}

.other-links-right .box .imgs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.other-links-right .box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

div {
    display: block;
}

/* links info end */

.btn-to-top {
    position: fixed;
    right: 10px;
    bottom: 10px;
}
.rounded-circles {
    background: #f16468;
}
.rounded-top {
    width: 55px;
    height: 55px;
}