.news-wrapper {
    width: 100%;
    height: auto;
    position: relative;
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#121212), to(#000));
    background-image: linear-gradient(180deg, #121212, #000);
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
    /* padding: 10px 0px; */
}

.index-section ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    zoom: 1;
}

element.style {
    width: 100000px;
    transform: translateX(1224px);
    animation: 45.108s linear 1s infinite normal none running marqueeAnimation-4314436;
}

.marquee {
    font-size: 23px;
}



.marquee-text {
    font-size: 23px;
    color: #b79d0e;
}

/* games list */
.gameslist {
    background-image: url(https://download.ocms365.com/v2/jlk/awd/memDesktop/img/gamelist-bg_e49add0.png);
    height: auto;
    padding: 40px 0px;

}

.games {
    width: 100%;
    height: 60px;
    margin-bottom: 30px;
    padding: 0 45px;
    background: hsla(0, 0%, 100%, .2);
    border-radius: 20px;
}

.games-link {
    display: block;
    padding: 0 10px;
    line-height: 60px;
    font-size: 25px;
    font-weight: 700;
    color: #aaa;
}

.games-link.active {
    color: #b79d0e;
    -webkit-background-clip: text;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(4%, #f8ecb0), color-stop(51%, #dda940), color-stop(99%, #d69f31));
    background-image: linear-gradient(90deg, #f8ecb0 4%, #dda940 51%, #d69f31 99%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.game-items {
    width: 31.3%;
    min-height: 100vh;
    position: relative;
    display: inline-block;
    margin: 0 1% 20px;
    padding: 3px;
    background: -webkit-gradient(linear, left top, left bottom, from(#fbeb91), color-stop(10%, #bd8355), color-stop(27%, #82523b), color-stop(45%, #fee185), color-stop(60%, #ffea7a), color-stop(90%, #b4784e), to(#fbeb91));
    background: linear-gradient(180deg, #fbeb91, #bd8355 10%, #82523b 27%, #fee185 45%, #ffea7a 60%, #b4784e 90%, #fbeb91);
    border-radius: 10px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5), 0 0 5px 0 #f9dd3f;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5), 0 0 5px 0 #f9dd3f;
    justify-content: center;

}


.games-border {
    width: 31.3%;
    height: auto;
    position: relative;
    display: inline-block;
    margin: 0 1% 20px;
    padding: 3px;
    background: -webkit-gradient(linear, left top, left bottom, from(#fbeb91), color-stop(10%, #bd8355), color-stop(27%, #82523b), color-stop(45%, #fee185), color-stop(60%, #ffea7a), color-stop(90%, #b4784e), to(#fbeb91));
    background: linear-gradient(180deg, #fbeb91, #bd8355 10%, #82523b 27%, #fee185 45%, #ffea7a 60%, #b4784e 90%, #fbeb91);
    border-radius: 10px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5), 0 0 5px 0 #f9dd3f;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .5), 0 0 5px 0 #f9dd3f;

}

.game-hover img {

    height: auto;
    width: 100% !important;

}

/* games list end */

/* other-links-section */
.other-links-section {
    background-color: #042F49;
    color: white;
    height: 1500px;
    z-index: 0;
}

.other-links-sections {
    background-color: #042F49;
    height: 80vh;
    width: 100%;
}

.youtube-content {
    height: 83%;
}

.fb-content {
    height: 100%;
}


/* other-links-section end */

/* goal wrapper */
.Goalwrapper {
    width: 100%;
    height: auto;
    padding: 50px 6%;
}

.img-goal {
    height: auto;
    width: 100%;
}

.ranking-box .ranking.dragon {
    background: -webkit-gradient(linear, left top, left bottom, from(#bf1d30), color-stop(50%, #dfafb5), to(#bf1d30));
    background: linear-gradient(180deg, #bf1d30 0, #dfafb5 50%, #bf1d30);
    border-radius: 5px;
}

.ranking-box .ranking.tiger {
    background: -webkit-gradient(linear, left top, left bottom, from(#2872c7), color-stop(50%, #9eb8e5), to(#2872c7));
    background: linear-gradient(180deg, #2872c7 0, #9eb8e5 50%, #2872c7);
    border-radius: 5px;

}

.ranking-box .ranking {
    /* width: 49%; */
    height: auto;
    position: relative;
    padding: 20px 30px;
}


.ranking-box .ranking.dragon:before {
    background: url(https://download.ocms365.com/v2/jlk/awd/memDesktop/img/dragon_f341f73.png) no-repeat;
    background-size: cover;
}

.ranking-box .ranking.tiger:before {
    background: url(https://download.ocms365.com/v2/jlk/awd/memDesktop/img/tiger_84c8cbc.png) no-repeat;
    background-size: cover;
}

.ranking-box .ranking:before {
    width: 100px;
    height: 100px;
    content: "";
    position: absolute;
    right: 30px;
    bottom: 15px;
    z-index: 3;
    display: block;
}

.ranking-box .ranking.dragon:after {
    content: "";
    position: absolute;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: #2e1215;
    left: 3px;
    top: 3px;
}

.ranking-box .ranking.tiger:after {
    content: "";
    position: absolute;
    border-radius: 5px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: #192b3d;
    left: 3px;
    top: 3px;
}

.ranking-box .ranking {
    /* width: 49%; */
    height: auto;
    position: relative;
    padding: 20px 30px;
}

.ranking-box .ranking .ranking-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
    position: relative;
    z-index: 3;
}

.ranking .ranking-img .trophy-img {
    width: 100%;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 3;
    padding-bottom: 60%;
    border-radius: 10px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#131313), to(#131313)), -webkit-gradient(linear, left top, left bottom, from(#c8c8c8), to(#979797));
    background-image: linear-gradient(180deg, #131313, #131313), linear-gradient(180deg, #c8c8c8, #979797);
}

.ranking .ranking-img .ranking-img-border {
    width: 100%;
    height: auto;
    padding: 1px;
    background: -webkit-gradient(linear, left top, left bottom, from(#c8c8c8), to(#979797));
    background: linear-gradient(180deg, #c8c8c8, #979797);
    border-radius: 10px;
}

.ranking-box .ranking .ranking-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 700;
    color: #fff;
    position: relative;
    z-index: 4;
    padding-top: 10px;
}

/* goal cards */
.ranking .ranking-info .winner {
    font-size: 25px;
}

.ranking-info .player {
    color: #fdd600;
}

.mySwiper {
    width: 100%;
    height: 30%;
}

/* .SwipperSlide {
 f
} */

.container-relative {
    position: relative;
    text-align: center;
    color: white;
}

.text-right {
    font-size: 18px;
    z-index: 3;
    font-weight: 700;
    position: absolute;
    bottom: 45px;
    left: 30px;
    color: white;
}

.game-hover {
    position: relative;
}

.game-items {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.game-hover:hover {
    opacity: 0.3;
}

.game-hover:hover .middle {
    opacity: 1;
}


.btn-game-over {
    background-color: #f16468;
    width: 200px;
    font-size: 20px;
    font-weight: 700;
}

.border-fill {
    border-radius: 60px;
}

/* .community-wrap {
    display: none;
} */


/* .Or {
    font-size: 100px;
} */