 .footer-top {
     padding: 50px 8%;
     color: #fff;
     background: -webkit-gradient(linear, left top, left bottom, from(#005685), to(#000));
     background: linear-gradient(180deg, #005685, #000);
 }

 footer .title {
     font-size: 16px;
     text-transform: uppercase;
     color: #b5c2e0;
     position: relative;

     padding: 0 0 5px 5px;
     /* border: ; */
 }

 .border-footer {
     border-bottom: 1px solid #d8d8d8;
     width: 30%;
 }

 .border-footer-info {
     border-bottom: 1px solid #d8d8d8;
     width: 55%;
 }

 .border-footer-links {
     border-bottom: 1px solid #d8d8d8;
     width: 100%;
 }

 .footer-top p {
     font-size: 13px;
 }

 .footer-links {
     padding: 5px 0;
     font-size: 14px;
     color: #959494;
 }

 footer .footer-top .info {
     display: -webkit-box;
     display: -ms-flexbox;
     /* display: flex; */
     -ms-flex-pack: distribute;
     justify-content: space-around;
 }

 footer .footer-top .info .seo-box {
     width: 40%;
 }

 footer .footer-top .info .seo-box p {
     margin-top: 30px;
     color: hsla(0, 0%, 100%, .85);
 }


 footer .footer-top .info>div {
     display: inline-block;
     width: 20%;
     word-break: break-all;
     padding: 0 10px;
 }



 footer .footer-top .info>div .info-width {
     width: auto;
     float: left;
     zoom: 1;
 }

 footer .footer-top .info>div .info-width:after,
 footer .footer-top .info>div .info-width:before {
     content: " ";
     display: table;
 }

 /* title */
 footer .footer-top .info>div .title {
     font-size: 16px;
     text-transform: uppercase;
     color: #b5c2e0;
     position: relative;
     border-bottom: 1px solid #d8d8d8;
     padding: 0 0 5px 5px;
 }

 /* title end */

 /* nav */
 footer .footer-top .info .menu-box ul {
     padding: 5px;
     list-style-type: none;
 }

 footer .footer-top .info>div .nav {
     padding: 5px;
     list-style-type: none;
 }

 ul {
     display: block;
     list-style-type: disc;
     margin-block-start: 1em;
     margin-block-end: 1em;
     margin-inline-start: 0px;
     margin-inline-end: 0px;
     padding-inline-start: 40px;
 }

 ol,
 ul {
     margin-top: 0;
     margin-bottom: 10px;
 }


 footer .footer-top .info .menu-box ul li {
     margin: 10px 0;
 }

 footer .footer-top .info>div .nav li {
     margin: 10px 0;
 }

 /* nav end */

 /* ----------- */
 footer .footer-top>div .title {
     font-size: 16px;
     text-transform: uppercase;
     color: #b5c2e0;
     position: relative;
     border-bottom: 1px solid #d8d8d8;
     padding: 0 0 5px 5px;
 }

 footer .footer-top .info .menu-box ul {
     padding: 5px;
     list-style-type: none;
 }

 .links>div ul {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-pack: distribute;
     justify-content: space-around;
     padding: 0;
 }

 .links-ul {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-pack: distribute;
     justify-content: space-around;
     padding: 0;
 }

 .links-icon {
     width: 50px;
     height: 50px;
     background: #fff;
     border-radius: 50%;
 }

 /* links  */

 footer .footer-top .info>div {
     display: inline-block;
     width: 20%;
     word-break: break-all;
     padding: 0 10px;
 }

 .links>div ul {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-pack: distribute;
     justify-content: space-around;
     padding: 0;
 }

 .links>div ul {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-pack: distribute;
     justify-content: space-around;
     padding: 0;
 }

 .links>div ul li {
     overflow: hidden;
     position: relative;
     display: block;
     padding: 1px;
 }

 .links>div ul li a {
     width: 100%;
     height: 100%;
     overflow: hidden;
     position: relative;
     cursor: pointer;
 }

 .links-box .links {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     padding: 20px 0;
 }

 /* services */
 .service-box {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     padding: 0;
 }

 .service-box a {
     width: 50px;
     height: 50px;
     overflow: hidden;
     position: relative;
     display: block;
     cursor: pointer;
 }

 .service-box a .services {
     width: 50px;
     height: 50px;
     background: #fff;
     border-radius: 50%;
 }

 /* services end */


 /* brand */
 .brand {
     margin: 50px auto 0;
     width: 50%;
 }

 .brand h4 {
     font-size: 16px;
     text-align: center;
 }

 .mySwiper {
     width: auto !important;
 }

 .swiper-slide {
     flex-shrink: 0;
     width: 100% !important;
     height: 100%;
     position: relative;
     transition-property: transform;
     display: block;
     background: transparent !important;
 }
.swiper-slide-icon{
    width: 7% !important;
}
 /* .my-Swiper 
 {
    height: auto !important;
 } */
 .game-provider-icon {
     width: 30px !important;
     height: auto !important;
     background-color: transparent;
 }

 /* brand end */

 /* links end */

 /* footer bottom */
 .footer-bottom {
     padding: 0 8%;
     background: #1a1a1a;
 }

 .footer-bottom .copyright {
     font-size: 12px;
     line-height: 50px;
     text-align: center;
     color: #e5ebfa;
 }

 /* footer bottom end */