.navbar-section {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: -webkit-gradient(linear, left top, left bottom, from(#00598a), to(#111));
    background: linear-gradient(180deg, #00598a, #111);
}

.btn-header {
    width: 150px;
}

.btn-hover-login {
    height: auto;
    padding: 14px 0px;

    /* border-right: 10px; */
}

.btn-header-top-login {
    border-start-end-radius: 50px 20px;
}

.border-left {
    height: 100%;
    border-left: 1px solid white;
}

.btn-header-top {
    background-color: #f16468;
    border-radius: 10px;
    padding: 0 40px;
    cursor: pointer;
}

.btn-header {
    display: block;
    /* padding: 0 50px; */
    /* min-width: 200px; */
    /* line-height: 50px; */
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;

}

.btn-rights {
    background: -webkit-gradient(linear, left top, left bottom, from(#394460), to(#252d41));

}

.btn-rights.left {
    width: 50px;
    height: 50px;
    position: relative;
    display: block;
    background: -webkit-gradient(linear, left top, left bottom, from(#394460), to(#252d41));
    /* background: linear-gradient(180deg,#394460,#252d41); */
    border: 1px solid #d1cece;
    border-radius: 50%;
}

#container-menu-bottom {
    width: 100% !important;
    overflow-x: auto;
}

.nav_menu {
    width: 100% !important;
}

li.nav_menu {
    width: 400px !important;
}

#menu-div {
    width: 100%;
    overflow-x: auto;
}

/* menu container */
.menu-container {
    overflow-y: auto;
}


#menu-container table {
    border-collapse: collapse;
    /* width: 100% !important; */
    table-layout: fixed;
    white-space: nowrap;
}

.menu-container table td {
    width: 400px !important;
    /* margin: 0 5px !important; */
}

.menu-container td {
    width: 300px;
    /* padding: 0px 16px !important; */

}

#menu-container table td:nth-child(1),
#menu-container table td:nth-child(2),
#menu-container table td:nth-child(3),
#menu-container table td:nth-child(4),
#menu-container table td:nth-child(5) {
    width: 300px;
    /* margin: 0px 20px; */

}


/* .td-nav {
    padding: 0px 20px !important;
} */

.header-bottom {
    padding: 0 150px;
}


/* ------------ */
/* .community-wrap.dropdown:hover {
    display: block;
    width: 200px;
    border-radius: 10px;
} */

.community-wrap.dropdown {
    position: absolute;
    z-index: 100;
    background: #333;
    /* top: calc(100% + 1px); */
    top: 86px;
    border-radius: 5px;
}

/* ------------ */


/* menu container end */


/* swiper */
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* swiper end */



@media (max-width: 767px) {
    .header-bottom {
        padding: 0 30px;
    }
}