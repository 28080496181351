* {
  outline: none;
  border: none;
  text-decoration: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover{
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background:  #292e33 ;
}

::-webkit-scrollbar-thumb { 
  background:#212529;
}

::-webkit-scrollbar-thumb:hover{
  background: var(--scrollbar-active)
}

